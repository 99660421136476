import React, { useEffect, useState } from 'react';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useMsal } from "@azure/msal-react";
import LoginDropdown from './mobile/LoginDropdown';
import { isDeviceMobile } from '../../util/device';

const Login = () => {
    const { instance } = useMsal();
    const [appPath, setAppPath] = useState("home"); 
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(isDeviceMobile);
    }, [])

    // Set default redirection route for mobile web
    useEffect(() => { 
        if (isMobile)
            setAppPath("dsrm/");
    }, [isMobile]);

    const handleLoginRedirect = () => {
        let loginRequest = {
            state: appPath,
            prompt: "select_account"
        }
        console.log("Initiating login redirect with Entra using MSAL");

        instance.loginRedirect(loginRequest).catch((error) => {
            console.error("Error logging in with Entra using MSAL");
            console.error(error);
        });
    };

    return (
        <div className="container-fluid vh-100">
            <div className="row h-100">
                <div className="col-md-3 d-flex align-items-center justify-content-center background-test">
                    <div className="text-center">
                        <div className="btn g3Logo"></div>
                        <div>
                            {isMobile &&
                            <LoginDropdown
                                options={[
                                {
                                    title: 'DSR',
                                    appPath: "dsrm/",
                                },
                                {
                                    title: 'Other',
                                    appPath: "home"
                                },
                                ]}
                                setAppPath={setAppPath}
                            />}
                            <button className="btn btn-primary btn-warning main-login-button" onClick={handleLoginRedirect}>Login</button>
                        </div>
                        <div className="trouble-signing-in">
                            <span>
                                Trouble signing in? Please email{' '}
                                <a href="mailto:support@gatethree.com">
                                    support@gatethree.com
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-md-9 p-0">
                    <div className="gate3_picture"></div>
                </div>
            </div>
        </div>
    );
};

export default Login;