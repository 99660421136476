import React, { Component } from 'react';
import '../../../App.css';
class GateThreeiFrameHandler extends Component {

    constructor(props) {
      super(props);
      const {url} = this.props;
      this.state = {
        closeCurtain: false,
        url: url,
        originalUrl:url
      };
      this.cookieToLookFor = 'accessToken';
      this.refreshNeeded = false;
    }
  
    componentDidMount() {
      if (this.getCookie(this.cookieToLookFor)) {
        this.lookForCookie(this.cookieToLookFor, false, 1000, this.sendToLoginPage);
      } else {
        this.lookForCookie(this.cookieToLookFor, true, 1000, this.refreshIframe);
      }
  
      document.addEventListener("visibilitychange", this.handleVisibilityChange);
      window.addEventListener('focus', this.handleWindowFocus);
      window.addEventListener('blur', this.handleWindowBlur);
    }
  
    componentWillUnmount() {
      document.removeEventListener("visibilitychange", this.handleVisibilityChange);
      window.removeEventListener('focus', this.handleWindowFocus);
      window.removeEventListener('blur', this.handleWindowBlur);
    }

    lookForCookie = (cookieName, value, interval, callback) => {
      if (value ? this.getCookie(cookieName) : !this.getCookie(cookieName)) {
        setTimeout(() => callback(), 0);
      } else {
        setTimeout(() => this.lookForCookie(cookieName, value, interval, callback), interval);
      }
    }

    sendToLoginPage = () => {
      console.log('send to login page');
      let redirect_url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
      this.setState({ url: `${redirect_url}` });
      this.lookForCookie(this.cookieToLookFor, true, 1000, this.refreshIframe);
    }
  
    refreshIframe = () => {
      let isED = this.getCookie('isED');
      setTimeout(() => {
        if (isED) {
          // set this to true, so when the tab or window is focused, the iframe will be refreshed
          this.refreshNeeded = true;
          this.setState({ closeCurtain: true });
        } else {
          // if the user is not ED, then we need to refresh the entire application
          window.location.reload();
        }
      }, 1000);
    }
  
    handleVisibilityChange = (e) => {
      console.clear();
      console.log(`Your page is ${document.visibilityState}`, Date.now());
      if (document.visibilityState === 'visible') {
        console.log('lets check if we need to refresh:', this.refreshNeeded);
        if (this.refreshNeeded) {
          this.refreshNeeded = false;
          this.forceRefreshIframe();
        }
      }
    }
  
    handleWindowFocus = (e) => {
      console.log('window focused, do we need to refresh:', this.refreshNeeded);
      if (this.refreshNeeded) {
        this.refreshNeeded = false;
        this.forceRefreshIframe();
      }
    }
  
    handleWindowBlur = () => {
      console.log('window lost focus');
    }
  
    forceRefreshIframe = () => {
      this.setState({
        url: this.state.originalUrl,
        closeCurtain: false
      });
      this.lookForCookie(this.cookieToLookFor, false, 1000, this.sendToLoginPage);
    }
  
    handlePageActivity = () => {
      console.log('handling page activity', this.refreshNeeded);
      if (this.refreshNeeded) {
        this.refreshNeeded = false;
        this.forceRefreshIframe();
      }
    }
  
    handleLoad = () => {
      console.log('page loaded, do we need to refresh:', this.refreshNeeded);
      if (this.refreshNeeded) {
        this.refreshNeeded = false;
        this.forceRefreshIframe();
      }
    }

    getCookie (cookieName) {
      const cookies = document.cookie
          .split("; ")
          .find((row) => row.startsWith(`${cookieName}=`));
      return cookies ? cookies.split("=")[1] : null;
    }
  
    render() {
      return (
        <div id="umbra-container" className="umbra">
          {this.state.closeCurtain ? (
            <div
              className="umbra__overlay"
              onMouseEnter={this.handlePageActivity}
              onMouseMove={this.handlePageActivity}
              onClick={this.handlePageActivity}>
              <h1>Loading...</h1>
            </div>
          ) : null}
          <iframe
            id="umbra-iframe"
            ref={r => this.iframe = r}
            src={this.state.url}
            onLoad={this.handleLoad}
          />
        </div>
      );
    }
  }
  
  export default GateThreeiFrameHandler;