import React, { Component } from 'react';
import cx from 'classnames';

class LoginDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      selectedOption: "0"
    }
  }

  componentDidMount() {
    window.addEventListener('click', this.clickListener);
    window.addEventListener("keypress", this.handleKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.clickListener);
    window.removeEventListener("keypress", this.handleKeyPress);
  }

  clickListener = (e) => {
    const { active } = this.state;
    const { target } = e;

    if (active && !this.ref.contains(target)) {
      this.toggleDrawer();
    }
  }

  handleKeyPress = (event) => {
    const { disabled } = this.props;
    if (event.key === 'Enter' && !disabled) {
      this.handleLogin();
    }
  }

  toggleDrawer = () => {
    const { active } = this.state;
    this.setState({ active: !active });
  }

  handleOptionClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { target: { value: index } } = e;
    this.setState({ selectedOption: index });
    const { options, setAppPath } = this.props;
    setAppPath(options[index].appPath);
  }

  render() {
    const { active, selectedOption } = this.state;
    const { className, options, disabled } = this.props;
    return (
      <div
        ref={r => this.ref = r}
        id="login-selector"
        className={cx("login-selector", {
          [className]: !!className
        })}>

          <div className="login-selector__container">
            <div className={cx("login-selector__icon", {
              "login-selector__icon--active": active
            })} />
            <select
              id="actions"
              name="actions"
              onChange={this.handleOptionClick}
              value={selectedOption}
              disabled={disabled}
            >
              {options && options.map((option, index) => {
                return (
                  <option value={index}>
                    {option.title}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
    )}
}

export default LoginDropdown;