import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import Login from './components/unauthenticated/Login';
import TokenManager from "./components/authenticated/util/TokenManager";

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <AuthenticatedTemplate>
        <TokenManager />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login/>
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};
export default App;
