import { React } from 'react';
import "./Loader.css";
const LogoLoader = () => {

    return (
        <div class="loader">
            <div class="logo">
                <div class="loader-tabs">
                    <div class="loader-tab"></div>
                    <div class="loader-tab"></div>
                    <div class="loader-tab"></div>
                </div>
            </div>
        </div>
    );
};

export default LogoLoader;