import { useEffect, useState } from 'react';
import RadixRequestHandler from '../radix/RadixRedirectHandler';
import GateThreeiFrameHandler from "./GateThreeiFrameHandler";
import { useMsal } from '@azure/msal-react';
import LogoLoader from "../logo/LogoLoader";
import { getCookie, deleteCookie } from '../../../util/cookie';
import { siteLogout } from '../../../util/logout';

const  GateThreeSessionHandler = ({ token }) => {
    const { instance } = useMsal();
    const [gateThreeUserSession, setGateThreeUserSession] = useState(null);
    const [gateThreeSrc, setGateThreeSrc] = useState(null);

    useEffect(() => {
        getGateThreeSession();
    }, []);

    const getGateThreeSession = async () => {
        try {
            const options = {
                method: "GET",
                headers: new Headers({
                    "Authorization": `Bearer ${token}`,
                    "accept": "application/json"
                }),
                credentials: 'include'
            };
            console.log("Calling gateThree usersession express endpoint");
            const response = await fetch(`/gatethree/usersession`, options);
            if (!response.ok) {
                throw new Error("Calling gateThree express endpoint failed");
            }
            console.log("Successfully retrieved gateThree user session");
            const data = await response.json();
            setGateThreeUserSession(data);

            const g3SrcCookie = getCookie("gateThreeModuleLink");
            if (g3SrcCookie) {
                setGateThreeSrc(decodeURIComponent(g3SrcCookie));
                deleteCookie("gateThreeModuleLink");
            }
        }
        catch (error) {
            console.error("Error fetching Gate Three user session: ", error);
            alert("Uh-oh! Something went wrong. Please try again, or contact support if the issue persists.")
            siteLogout();
        }
    };

    console.debug("userSession: ", gateThreeUserSession, ", G3 Source: ", gateThreeSrc);

    if (gateThreeUserSession && gateThreeSrc)
        return <GateThreeiFrameHandler url={gateThreeSrc} />
    else if (gateThreeUserSession)
        return <div><RadixRequestHandler token={token} /></div>
    return <div><LogoLoader /></div>;
};

export default GateThreeSessionHandler;