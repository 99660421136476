import { datadogRum } from "@datadog/browser-rum";
import { detectDeviceType } from "../../../util/device";

const configurationConstants = {
    applicationId: '020b5b54-e836-4915-b675-929506d19375',
    clientToken: 'pub5eed53ba352adc35cc400d464e1c56b7',
    site: 'datadoghq.com',
    serviceName: 'radix---login-page',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100
  };

const addLeadingZero = (x) => {
    if (x < 10) x = `0${x}`;
    return x;
  };
  
export const formatDateString = (x) => {
  let date = x ? new Date(x) : null;
  if (!date) return null;

  let [d, t] = new Date(+date).toLocaleString().split(',');
  let [month, day, year] = d.split('/');
  month = addLeadingZero(month);
  day = addLeadingZero(day);
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

class DataDogController {
  constructor() {
    this.deviceType = null;
    datadogRum.init({
      applicationId: configurationConstants.applicationId,
      clientToken: configurationConstants.clientToken,
      site: configurationConstants.datadogSite,
      service: configurationConstants.serviceName,
      env: process.env.REACT_APP_ENV,
      sessionSampleRate: configurationConstants.sessionSampleRate,
      sessionReplaySampleRate: configurationConstants.sessionReplaySampleRate,
      trackInteractions: true,
    });
  }

  setLoginInfo(userInfo) {
    try {
      if (!datadogRum) 
        return;
      
      console.log("Recording login info in Datadog");
      datadogRum.addAction('login-info', {
        currentDate: formatDateString(new Date()),
        usr: { email: userInfo.email, deviceType: this.deviceType },
      });
    } catch (error) {
      console.error(
        `Unable to add 'login-info' action in Datadog due to ${error}`
      );
    }
  }

  setDeviceType(deviceType) {
    this.deviceType = deviceType;
  }

  recordUserInfo(email) {
    const deviceType = detectDeviceType();
    this.setDeviceType(deviceType);
    this.setLoginInfo({ email: email});
  }
}

export default new DataDogController();
