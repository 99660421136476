import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { msalConfig } from "./authConfig.js";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import DatadogController from './components/authenticated/util/DatadogController.jsx'
/**
 * MSAL should always be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    msalInstance.setActiveAccount(event.payload.account);
    console.log("Login successful setting active account as ", msalInstance.getActiveAccount().username);
    DatadogController.recordUserInfo(msalInstance.getActiveAccount().username);
    const expiresIn24Hours = new Date(Date.now() + 24 * 60 * 60 * 1000);
    document.cookie = `appPath=${event.payload.state}; expires=${expiresIn24Hours.toUTCString()}; secure;`; // Sets the path to redirect to after login
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App instance={msalInstance} />);