import { useEffect } from 'react';
import LogoLoader from "../logo/LogoLoader";
import { siteLogout } from '../../../util/logout';

const RadixRedirectHandler = ({ token }) => {
  useEffect(() => {
    redirectToRadixHomePage();
  }, []);

  const redirectToRadixHomePage = async () => {
    try {
      const options = {
        method: "GET",
        headers: new Headers({
          "Authorization": `Bearer ${token}`,
          "accept": "application/json"
        }),
      };
      
      console.log("Calling radix express endpoint");
      const response = await fetch(`/radix`, options);
      if (!response.ok) {
        throw new Error('Failed to redirect to Radix home page');
      }
      const data = await response.json();
      console.log("Redirecting to home page");
      window.location.href = data.redirect;
    } 
    catch (error) {
      console.error("Error redirecting to Radix home page:", error);
      siteLogout();
    }
  };
  return <div><LogoLoader /></div>;
};

export default RadixRedirectHandler;